import styles from './modal.module.css';

import { useImperativeHandle } from 'react';
import { forwardRef, useRef, useState } from 'react';
import classNames from 'classnames';

import { ModalProps, ModalRef, ModalHeadProps, ModalBodyProps, ModalFooterProps } from './modal.types';

const Modal: React.ForwardRefRenderFunction<ModalRef, React.PropsWithChildren<ModalProps>> = (props, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [transformOrigin, setTransformOrigin] = useState('');
  const dialogRef = useRef<HTMLDivElement>(null);

  const show = () => {
    setIsVisible(true);

    const { current: dialogElement } = dialogRef;

    if (document.activeElement && dialogElement) {
      const activeElementRect = document.activeElement.getBoundingClientRect();

      const offsetX = activeElementRect.x - dialogElement.offsetLeft;
      const offsetY = activeElementRect.y - dialogElement.offsetTop;

      setTransformOrigin(`${offsetX}px ${offsetY}px`);
    } else {
      setTransformOrigin('50% 50%');
    }
  };

  const hide = () => {
    setIsVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.visible]: isVisible,
        },
        props.className
      )}
      style={props.style}
      onClick={() => hide()}
    >
      <div ref={dialogRef} className={styles.dialog} style={{ transformOrigin }} onClick={(ev) => ev.stopPropagation()}>
        {isVisible && props.children}
      </div>
    </div>
  );
};

export const Head = (props: React.PropsWithChildren<ModalHeadProps>) => {
  return (
    <div className={classNames(styles.head, props.className)} style={props.style}>
      {props.children}
    </div>
  );
};

export const Body = (props: React.PropsWithChildren<ModalBodyProps>) => {
  return (
    <div className={classNames(styles.body, props.className)} style={props.style}>
      {props.children}
    </div>
  );
};

export const Footer = (props: React.PropsWithChildren<ModalFooterProps>) => {
  return (
    <div className={classNames(styles.footer, props.className)} style={props.style}>
      {props.children}
    </div>
  );
};

export default forwardRef(Modal);
