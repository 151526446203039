import * as Types from '../shared/helpers/types/graphql.generated';

import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from '@/shared/helpers/fetcher';
export type GetSessionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSessionQuery = { session?: { _id: any, name: string, email: string, foundedAt?: string | null, subscribersCount: number, subscribedToCount: number, avatar?: { filepath?: string | null } | null, subscribedTo: Array<{ _id: any }> } | null };


export const GetSessionDocument = `
    query GetSession {
  session {
    _id
    name
    email
    avatar {
      filepath
    }
    foundedAt
    subscribersCount
    subscribedToCount
    subscribedTo {
      _id
    }
  }
}
    `;
export const useGetSessionQuery = <
      TData = GetSessionQuery,
      TError = unknown
    >(
      variables?: GetSessionQueryVariables,
      options?: UseQueryOptions<GetSessionQuery, TError, TData>
    ) =>
    useQuery<GetSessionQuery, TError, TData>(
      variables === undefined ? ['GetSession'] : ['GetSession', variables],
      fetcher<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, variables),
      options
    );