import styles from "./button.module.css";

import classNames from "classnames";

import { ButtonProps, ButtonVariants } from "./button.types";

import Loader from "@/shared/components/loader";
import Icon from "@/shared/components/icon";

export const Button = (props: React.PropsWithChildren<ButtonProps>) => {
  const onClick = () => {
    if (!props.disabled && !props.loading) {
      props.onClick?.();
    }
  }

  return (
    <button
      className={classNames(
        props.className,
        styles.button,
        props.variant ? styles[props.variant] : null,
        {
          [styles.prefix]: !!props.prefix,
          [styles.suffix]: !!props.suffix,
          [styles.loading]: props.loading,
          [styles.icon]: (props.children as React.ReactElement)?.type === Icon,
        }
      )}
      disabled={props.disabled}
      onClick={onClick}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    >
      <Loader active={props.loading} />
      {props.prefix && (
        <>
          <div className={styles.prefix}>{props.prefix}</div>
          <div className={styles.divider}></div>
        </>
      )}
      <div className={styles.main}>{props.children}</div>
      {props.suffix && (
        <>
          <div className={styles.divider}></div>
          <div className={styles.suffix}>{props.suffix}</div>
        </>
      )}
    </button>
  );
};

Button.defaultProps = {
  variant: ButtonVariants.Default,
};
