import { endpoint } from "./fetcher"

export const getFilePath = (path?: string | null) => {
  if (!path) {
    return "";
  }

  if (path.startsWith("http")) {
    return path;
  }
  return endpoint + path;
}