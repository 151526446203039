import styles from "./notifications.module.css";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";

import { NotificationProps } from "./notifications.types";
import { NOTIFY_EVENT_NAME } from "./notifications.constants";

const Notifications = () => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const notificationsRef = useRef<NotificationProps[]>(notifications);

  const [notificationsRefs, setNotificationsRefs] = useState<
    React.MutableRefObject<HTMLDivElement | null>[]
  >([]);

  const addNotification = (notificationToAdd: NotificationProps) => {
    setNotifications([notificationToAdd, ...notificationsRef.current]);
  };

  const removeNotification = (notificationToRemove: NotificationProps) => {
    setNotifications(
      notificationsRef.current.filter(
        (notification) => notification !== notificationToRemove
      )
    );
  };

  useEffect(() => {
    notificationsRef.current = notifications;
    setNotificationsRefs(notifications.map(() => ({ current: null })));
  }, [notifications]);

  useEffect(() => {
    function onNotify(ev: CustomEvent<NotificationProps>) {
      addNotification(ev.detail);

      setTimeout(() => removeNotification(ev.detail), 2000);
    }

    document.addEventListener(NOTIFY_EVENT_NAME, onNotify as EventListener);

    return () =>
      document.removeEventListener(
        NOTIFY_EVENT_NAME,
        onNotify as EventListener
      );
  }, []);

  useEffect(() => {
    let top = 0;

    for (let i = 0; i < notificationsRefs.length; ++i) {
      const { current: currentElement } = notificationsRefs[i];

      if (notificationsRefs[i - 1]) {
        const { current: previousElement } = notificationsRefs[i - 1];

        if (previousElement) {
          top += previousElement.getBoundingClientRect().height + 16;
        }
      }

      if (currentElement) {
        currentElement.style.top = `${top}px`;
      }
    }
  }, [notificationsRefs]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <TransitionGroup component={null}>
          {notifications.map((notification, i) => (
            <CSSTransition
              key={notification._id}
              classNames={{
                appear: styles.notificationAppear,
                appearActive: styles.notificationAppearActive,
                appearDone: styles.notificationAppearDone,
                enter: styles.notificationEnter,
                enterActive: styles.notificationEnterActive,
                enterDone: styles.notificationEnterDone,
                exit: styles.notificationExit,
                exitActive: styles.notificationExitActive,
                exitDone: styles.notificationExitDone,
              }}
              timeout={300}
            >
              <div ref={notificationsRefs[i]} className={styles.notification}>
                {!!notification.prefix && (
                  <div className={styles.prefix}>{notification.prefix}</div>
                )}
                <div className={styles.message}>{notification.message}</div>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default Notifications;
