import { ReactNode } from "react";

export enum ButtonVariants {
  Default = "default",
  Primary = "primary",
  Text = "text",
}

export interface ButtonProps {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  variant?: ButtonVariants;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}