import styles from "./spinner.module.css";

const Spinner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.shape} data-shape="1"></div>
      <div className={styles.shape} data-shape="2"></div>
      <div className={styles.shape} data-shape="3"></div>
      <div className={styles.shape} data-shape="4"></div>
    </div>
  );
};

export default Spinner;
