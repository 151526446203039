import { ReactNode } from 'react';

const jsxIf = (condition: any, children: ReactNode) => {
  if (!condition) {
    return null;
  }

  return children;
}

export const jsx = {
  if: jsxIf,
}