import classNames from 'classnames';
import styles from './pricing.module.css';
import { Icon } from '../../../shared/components/icon/icon';
import { Button } from '../../../shared/components/button/button';
import { ButtonVariants } from '../../../shared/components/button';
import { useTranslation } from 'react-i18next';

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.title}>{t('authorized.pricing.title')}</div>
        <div className={styles.subtitle}>{t('authorized.pricing.description')}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.title}>{t('authorized.pricing.basic.title')}</div>
          <div className={styles.list}>
            {(t('authorized.pricing.basic.features', { returnObjects: true }) as string[]).map((feature) => (
              <div className={styles.listItem}>
                <Icon className={styles.icon} name="done" />
                {feature}
              </div>
            ))}
          </div>
          <div className={styles.price}>{t('authorized.pricing.price', { price: 4.99 })}</div>
          <Button>{t('authorized.pricing.choose')}</Button>
        </div>
        <div className={classNames(styles.item, styles.highlighted)}>
          <div className={styles.title}>{t('authorized.pricing.advanced.title')}</div>
          <div className={styles.list}>
            {(t('authorized.pricing.advanced.features', { returnObjects: true }) as string[]).map((feature) => (
              <div className={styles.listItem}>
                <Icon className={styles.icon} name="done" />
                {feature}
              </div>
            ))}
          </div>
          <div className={styles.price}>{t('authorized.pricing.yetToCome')}</div>
          {/* <Button>{t('authorized.pricing.tryForFree')}</Button> */}
        </div>
        <div className={classNames(styles.item)}>
          <div className={styles.title}>{t('authorized.pricing.pro.title')}</div>
          <div className={styles.list}>
            {(t('authorized.pricing.pro.features', { returnObjects: true }) as string[]).map((feature) => (
              <div className={styles.listItem}>
                <Icon className={styles.icon} name="done" />
                {feature}
              </div>
            ))}
          </div>
          <div className={styles.price}>{t('authorized.pricing.yetToCome')}</div>
          {/* <Button>{t('authorized.pricing.choose')}</Button> */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
