import styles from "./form.module.css";

import React, {useImperativeHandle, useState, createRef, forwardRef, useEffect} from "react";
import classNames from "classnames";

import { FormRef, FormProps, FormValue } from "./form.types";

const Form: React.ForwardRefRenderFunction<FormRef, FormProps> = (props, ref) => {
  const [value, setValue] = useState<FormValue>({});
  const [childrenRef, setChildrenRef] = useState<{ [key: string]: React.RefObject<any> }>({});

  useImperativeHandle(ref, () => ({
    clearValue: () => {
      setValue({});

      Object.values(childrenRef).forEach(ref => ref?.current?.clearValue());
    },
    setValue: (value: FormValue) => {
      Object.keys(value).forEach((key) => {
        childrenRef[key]?.current?.setValue(value?.[key]);
      });
    }
  }));

  const onChildChange = (childName: string, childValue: unknown) => {
    const newValue = { ...value, [childName]: childValue };

    setValue(newValue);

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const transformChildren = (children: FormProps["children"]) => {
    const refs: { [key: string]: React.RefObject<any> } = {};

    const result =  React.Children.map(
      Array.isArray(children) ? children : [children],
      (child) => {
        if (React.isValidElement(child) && child.props.name) {
          refs[child.props.name] = createRef();

          const { name: childName } = child.props;

          return React.cloneElement(child, {
            //@ts-ignore
            ref: refs[child.props.name],
            onChange: (childValue: unknown) =>
              onChildChange(childName, childValue),
          });
        }
        return child;
      }
    );

    setChildrenRef(refs);

    return result;
  };

  const [children, setChildren] = useState<React.ReactNode>();

  useEffect(() => {
    setChildren(transformChildren(props.children ?? []));
  }, [props.children])

  return (
    <div
      className={classNames(styles.form, props.className)}
      style={props.style}
    >
      {children}
    </div>
  );
};

export default forwardRef(Form);