import * as Types from '../../../shared/helpers/types/graphql.generated';

import { useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from '@/shared/helpers/fetcher';

function _updateFetcher<TData, TVariables>(query: string, variables?: TVariables) {
  const data = new FormData();

  data.append("operations", JSON.stringify({
    query,
    variables,
  }));

  const map: any = {};

  if ((variables as any).input.avatar) {
    map["0"] = ["variables.input.avatar"];
  }

  if ((variables as any).input.cover) {
    map["1"] = ["variables.input.cover"];
  }

  data.append('map', JSON.stringify(map));

  data.append("0", (variables as any).input.avatar);
  data.append("1", (variables as any).input.cover);

  return fetcher<TData, TVariables>(query, variables, data, "UpdateCompany");
}

export type UpdateCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['ObjectId'];
  input: Types.UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { updateCompany: { _id: any } };


export const UpdateCompanyDocument = `
    mutation UpdateCompany($id: ObjectId!, $input: UpdateCompanyInput!) {
  updateCompany(id: $id, input: $input) {
    _id
  }
}
    `;
export const useUpdateCompanyMutation = <
  TError = unknown,
  TContext = unknown
  >(options?: UseMutationOptions<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>) =>
  useMutation<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>(
    ['UpdateCompany'],
    (variables?: UpdateCompanyMutationVariables) => _updateFetcher<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, variables)(),
    options
  );


function _uploadFetcher<TData, TVariables>(query: string, variables?: TVariables) {
  const data = new FormData();

  data.append("operations", JSON.stringify({
    query,
    variables,
  }));

  const map: any = {};

  map["0"] = ["variables.file"];

  data.append('map', JSON.stringify(map));

  data.append("0", (variables as any).file);

  return fetcher<TData, TVariables>(query, variables, data, "UploadCompanyImage");
}

export type UploadCompanyImageMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload'];
}>;


export type UploadCompanyImageMutation = { uploadImage: { filepath?: string | null, mimetype?: string | null, encoding?: string | null } };

export const UploadCompanyImageDocument = `
    mutation UploadCompanyImage($file: Upload!) {
  uploadImage(file: $file) {
    filepath
    mimetype
    encoding
  }
}
    `;
export const useUploadCompanyImageMutation = <
  TError = unknown,
  TContext = unknown
  >(options?: UseMutationOptions<UploadCompanyImageMutation, TError, UploadCompanyImageMutationVariables, TContext>) =>
  useMutation<UploadCompanyImageMutation, TError, UploadCompanyImageMutationVariables, TContext>(
    ['UploadCompanyImage'],
    (variables?: UploadCompanyImageMutationVariables) => _uploadFetcher<UploadCompanyImageMutation, UploadCompanyImageMutationVariables>(UploadCompanyImageDocument, variables)(),
    options
  );