import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import CompanyEdit from '@/authorized/pages/company-edit';
import About from '../pages/about';
import Pricing from '../pages/pricing/pricing';

const Home = lazy(() => import('../pages/home'));
const Explore = lazy(() => import('../pages/explore'));
const Messages = lazy(() => import('../pages/messages'));
const Company = lazy(() => import('@/authorized/pages/company'));

const routes: RouteProps[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/explore',
    element: <Explore />,
  },
  {
    path: '/messages',
    element: <Messages />,
  },
  {
    path: '/company/:id',
    element: <Company />,
  },
  {
    path: '/company/:id/edit',
    element: <CompanyEdit />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/pricing',
    element: <Pricing />,
  },
];

export default routes;
