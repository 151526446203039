import styles from './page.module.css';

import { useEffect, useState } from 'react';
import classNames from 'classnames';

import Loader from '@/shared/components/loader';

import { PageProps } from './page.types';

const Page = (props: React.PropsWithChildren<PageProps>) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(!!props.loading);
  }, [props.loading]);

  return (
    <div className={styles.page}>
      <Loader className={styles.loader} active={loading} />
      <div className={classNames(styles.content, props.className)}>{props.children}</div>
    </div>
  );
};

export default Page;
