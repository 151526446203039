import { lazy } from "react";
import { RouteProps } from "react-router-dom";

const Login = lazy(() => import("../pages/login"));
const Signup = lazy(() => import("../pages/signup"));

const routes: RouteProps[] = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
];

export default routes;
