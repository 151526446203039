import {useGetSessionQuery} from "@/app/app.operations.generated";
import {Company} from "@/shared/helpers/types";
import Loader from "@/shared/components/loader";

import Authorized from "@/authorized";
import Guest from "@/guest";
import {clearHeader, setHeader} from "@/shared/helpers/fetcher";
import {clearCookies, setCookie} from "@/shared/helpers";
import {history} from "@/authorized/components/router";

const App = () => {
  const { data, isFetching, refetch } = useGetSessionQuery();

  const onLogout = async () => {
    clearCookies("authorization");
    clearHeader("authorization");
    await refetch();
    history.push("/");
  }

  const onLogin = async (token: string) => {
    setCookie("authorization", token);
    setHeader("authorization", token);
    await refetch();
    history.push("/");
  };

  return (
    <>
      <Loader active={isFetching} />
      {!!data?.session ? <Authorized session={data.session as Company} onLogout={onLogout} /> : <Guest onLogin={onLogin} />}
    </>
  )
}

export default App;