import { getCookie } from '@/shared/helpers/cookies';
import env from 'react-dotenv';

const headers: Record<string, string> = {
  // "authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55IjoiNjJmZDIyYjljYWY4ZWU4MGZmMDFkMDRjIiwiaWF0IjoxNjYwODA5OTIxfQ.bSKawaq-b83xLfMe4cJbdz5dbm2gRr6QLryGQxL0hq0"
};

const token = getCookie('authorization');

if (token) {
  headers.authorization = token;
}

export const setHeader = (name: string, value: string) => {
  headers[name] = value;
};

export const clearHeader = (name: string) => {
  delete headers[name];
};

export const endpoint = process.env?.APP_URL || env?.APP_URL || 'http://localhost:4000';
// export const endpoint = 'https://negotium-app-v9tta.ondigitalocean.app';
export const graphQLendpoint = endpoint + '/graphql';

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  data?: FormData,
  operationName?: string
) => {
  return async (): Promise<TData> => {
    const res = await fetch(graphQLendpoint, {
      method: 'POST',
      headers: {
        ...headers,
        ...(data ? {} : { 'content-type': 'application/json' }),
        ...(operationName ? { 'x-apollo-operation-name': operationName } : {}),
      },
      body: data ?? JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
};
