import styles from './icon.module.css';

import classNames from 'classnames';

import { IconProps } from './icon.types';

export const Icon = (props: IconProps) => {
  const url = `/img/icons/${props.variant ?? 'outline'}/${props.name}.svg`;
  const mask = `url('${url}') 0 0 / cover`;

  return (
    <div
      className={classNames(styles.icon, props.className)}
      style={{
        width: props.size,
        height: props.size,
        minWidth: props.size,
        minHeight: props.size,
        mask,
        WebkitMask: mask,
      }}
    ></div>
  );
};

Icon.defaultProps = {
  variant: 'outline',
};
