import * as Types from '../../../shared/helpers/types/graphql.generated';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
import { fetcher } from '@/shared/helpers/fetcher';
export type GetCompanyEditDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ObjectId'];
}>;


export type GetCompanyEditDataQuery = { company?: { _id: any, name: string, description?: string | null, type?: string | null, annualSales?: string | null, foundedAt?: string | null, website?: string | null, employeesCount?: string | null, mission?: string | null, vision?: string | null, targetMarket?: string | null, aboutUs?: string | null, produceAndSell?: string | null, buy?: string | null, subscribersCount: number, subscribedToCount: number, aboutUsImages?: Array<{ filepath?: string | null } | null> | null, produceAndSellImages?: Array<{ filepath?: string | null } | null> | null, buyImages?: Array<{ filepath?: string | null } | null> | null, avatar?: { filepath?: string | null } | null, cover?: { filepath?: string | null } | null, subscribers: Array<{ company: { _id: any } }>, posts: Array<{ _id: any, title: string, content: string, likesCount: number, createdAt: string, cover?: { filepath?: string | null } | null, likes: Array<{ company: { _id: any } }>, company: { _id: any, name: string, avatar?: { filepath?: string | null } | null } }> } | null };

export type UploadCompanyImageMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload'];
}>;


export type UploadCompanyImageMutation = { uploadImage: { filepath?: string | null, mimetype?: string | null, encoding?: string | null } };

export type UpdateCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['ObjectId'];
  input: Types.UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { updateCompany: { _id: any } };


export const GetCompanyEditDataDocument = `
    query GetCompanyEditData($id: ObjectId!) {
  company(id: $id) {
    _id
    name
    description
    type
    annualSales
    foundedAt
    website
    employeesCount
    mission
    vision
    targetMarket
    aboutUs
    aboutUsImages {
      filepath
    }
    produceAndSell
    produceAndSellImages {
      filepath
    }
    buy
    buyImages {
      filepath
    }
    avatar {
      filepath
    }
    cover {
      filepath
    }
    subscribersCount
    subscribedToCount
    subscribers {
      company {
        _id
      }
    }
    posts {
      _id
      title
      cover {
        filepath
      }
      likes {
        company {
          _id
        }
      }
      company {
        _id
        name
        avatar {
          filepath
        }
      }
      content
      likesCount
      createdAt
    }
  }
}
    `;
export const useGetCompanyEditDataQuery = <
      TData = GetCompanyEditDataQuery,
      TError = unknown
    >(
      variables: GetCompanyEditDataQueryVariables,
      options?: UseQueryOptions<GetCompanyEditDataQuery, TError, TData>
    ) =>
    useQuery<GetCompanyEditDataQuery, TError, TData>(
      ['GetCompanyEditData', variables],
      fetcher<GetCompanyEditDataQuery, GetCompanyEditDataQueryVariables>(GetCompanyEditDataDocument, variables),
      options
    );
export const UploadCompanyImageDocument = `
    mutation UploadCompanyImage($file: Upload!) {
  uploadImage(file: $file) {
    filepath
    mimetype
    encoding
  }
}
    `;
export const useUploadCompanyImageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UploadCompanyImageMutation, TError, UploadCompanyImageMutationVariables, TContext>) =>
    useMutation<UploadCompanyImageMutation, TError, UploadCompanyImageMutationVariables, TContext>(
      ['UploadCompanyImage'],
      (variables?: UploadCompanyImageMutationVariables) => fetcher<UploadCompanyImageMutation, UploadCompanyImageMutationVariables>(UploadCompanyImageDocument, variables)(),
      options
    );
export const UpdateCompanyDocument = `
    mutation UpdateCompany($id: ObjectId!, $input: UpdateCompanyInput!) {
  updateCompany(id: $id, input: $input) {
    _id
  }
}
    `;
export const useUpdateCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>) =>
    useMutation<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>(
      ['UpdateCompany'],
      (variables?: UpdateCompanyMutationVariables) => fetcher<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, variables)(),
      options
    );