import styles from "./loader.module.css";

import Spinner from "@/shared/components/spinner";
import classNames from "classnames";
import { LoaderProps } from "./loader.types";

const Loader = (props: LoaderProps) => {
  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.active]: props.active,
        },
        props.className
      )}
      style={props.style}
    >
      <Spinner />
    </div>
  );
};

export default Loader;
