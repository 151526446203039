import styles from './feed.module.css';

import { Masonry } from '@mui/lab';

import { FeedProps } from './feed.types';
import { useWindowSize } from '@/shared/helpers';
import { useMemo } from 'react';

function Feed<T>(props: FeedProps<T>) {
  const { width } = useWindowSize();
  const columns = useMemo(() => {
    if (width < 1140) {
      return 1;
    }

    if (width < 1280) {
      return 2;
    }

    return 3;
  }, [width]);

  return (
    <div className={styles.feed} style={props.style}>
      {columns === 1 ? (
        <div className={styles.feed_list}>
          {props.data.map((item, index) => (
            <div key={index} className={styles.card}>
              {props.renderCard(item, index)}
            </div>
          ))}
        </div>
      ) : (
        <Masonry columns={columns} spacing={2} style={{ width: 'calc(100% + 16px)' }}>
          {props.data.map((item, index) => (
            <div key={index} className={styles.card}>
              {props.renderCard(item, index)}
            </div>
          ))}
        </Masonry>
      )}
    </div>
  );
}

export default Feed;
