import classNames from 'classnames';
import styles from './about.module.css';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.hero} style={{ backgroundImage: `url(/img/about/cover.png)` }}>
        <div className={styles.container}>
          <div className={styles.group}>
            <img className={styles.logo} src="/img/about/logo.png" alt="logo" />
            <div className={styles.title}>{t('authorized.aboutUs.title')}</div>
            <div className={styles.text}>{t('authorized.aboutUs.description')}</div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.group}>
          <div className={styles.title}>
            <img src="/img/about/icon_1.svg" alt="icon" />
            <div className={styles.text}>{t('authorized.aboutUs.whoWeAre.title')}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.line}></div>
            <div className={styles.sections}>
              <div className={styles.section}>
                <video className={styles.video} width="100%" autoPlay loop muted playsInline>
                  <source src="/img/about/video_1.mp4" type="video/mp4" />
                </video>
              </div>
              <div className={styles.section}>
                <div className={styles.imageAndText}>
                  <div className={styles.text}>
                    {(t('authorized.aboutUs.whoWeAre.sections', { returnObjects: true }) as string[])[0]}
                  </div>
                  <div className={styles.image}>
                    <div className={styles.inner}>
                      <img src="/img/about/1.png" alt="section" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                <div className={classNames(styles.imageAndText, styles.alternate)}>
                  <div className={styles.text}>
                    {(t('authorized.aboutUs.whoWeAre.sections', { returnObjects: true }) as string[])[1]}
                  </div>
                  <div className={styles.image}>
                    <div className={styles.inner}>
                      <img src="/img/about/2.png" alt="section" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.imageAndText}>
                  <div className={styles.text}>
                    {(t('authorized.aboutUs.whoWeAre.sections', { returnObjects: true }) as string[])[2]}
                  </div>
                  <div className={styles.image}>
                    <div className={styles.inner}>
                      <img src="/img/about/3.png" alt="section" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.hero, styles.alternate)}
        style={{ backgroundImage: `url(/img/about/cover_2.png)` }}
      >
        <div className={styles.container}>
          <div className={styles.group}>
            <div className={styles.title}>{t('authorized.aboutUs.howItWorks.title')}</div>
            <div className={styles.text}>{t('authorized.aboutUs.howItWorks.text')}</div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.group}>
          <div className={styles.title}>
            <img src="/img/about/icon_2.svg" alt="icon" />
            <div className={styles.text}>{t('authorized.aboutUs.growYourBusiness.title')}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.line}></div>
            <div className={styles.sections}>
              <div className={styles.section}>
                <div className={classNames(styles.imageAndText, styles.alternate)}>
                  <div className={styles.text}>{t('authorized.aboutUs.growYourBusiness.text')}</div>
                  <div className={styles.image}>
                    <div className={styles.inner}>
                      <img src="/img/about/4.png" alt="section" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.cards}>
                  <div className={styles.title}>{t('authorized.aboutUs.growYourBusiness.cards.title')}</div>
                  <div className={styles.items}>
                    {(
                      t('authorized.aboutUs.growYourBusiness.cards.items', { returnObjects: true }) as {
                        title: string;
                        text: string;
                      }[]
                    ).map((item: { title: string; text: string }, i) => (
                      <div className={styles.card}>
                        <div className={styles.title}>{item.title}</div>
                        <div className={styles.text}>{item.text}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.imageAndText}>
                  <div className={styles.text}>{t('authorized.aboutUs.growYourBusiness.team')}</div>
                  <div className={styles.image}>
                    <div className={styles.inner}>
                      <img src="/img/about/5.png" alt="section" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                <video className={styles.video} width="100%" autoPlay loop muted playsInline>
                  <source src="/img/about/video_2.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.hero, styles.alternate)}
        style={{ backgroundImage: `url(/img/about/cover_2.png)` }}
      >
        <div className={styles.container}>
          <div className={styles.group}>
            <div className={styles.title}>{t('authorized.aboutUs.whyYouShouldJoinUsFor.title')}</div>
            <div className={styles.grid}>
              {(
                t('authorized.aboutUs.whyYouShouldJoinUsFor.items', { returnObjects: true }) as {
                  title: string;
                  text: string;
                }[]
              ).map((item: { title: string; text: string }, i) => (
                <div className={styles.item}>
                  <div className={styles.label}>{item.title}</div>
                  <div className={styles.body}>{item.text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.group}>
            <div className={styles.section}>
              <img src="/img/about/logo.png" width={160} alt="Negotium" />
            </div>
            <div className={styles.section}>
              <div className={styles.sectionTitle}>{t('authorized.aboutUs.footer.contacts.title')}</div>
              <div className={styles.sectionContent}>
                <div className={styles.contacts}>
                  <div className={styles.contact}>
                    <div className={styles.label}>{t('authorized.aboutUs.footer.contacts.address')}</div>
                    <div className={styles.value}>Rue du Commerce 123, 1000 Brussels, Belgium</div>
                  </div>
                  <div className={styles.contact}>
                    <div className={styles.label}>{t('authorized.aboutUs.footer.contacts.phone')}</div>
                    <div className={styles.value}>+32 2 123 45 67</div>
                  </div>
                  <div className={styles.contact}>
                    <div className={styles.label}>{t('authorized.aboutUs.footer.contacts.email')}</div>
                    <div className={styles.value}>
                      <a href="mailto:sales@thenegotium.com">sales@thenegotium.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.sectionTitle}>{t('authorized.aboutUs.footer.social.title')}</div>
              <div className={styles.sectionContent}>
                <div className={styles.links}>
                  <div className={styles.link}>{t('authorized.aboutUs.footer.social.twitter')}</div>
                  <div className={styles.link}>{t('authorized.aboutUs.footer.social.facebook')}</div>
                  <div className={styles.link}>{t('authorized.aboutUs.footer.social.instagram')}</div>
                  <div className={styles.link}>{t('authorized.aboutUs.footer.social.linkedin')}</div>
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.sectionTitle}>{t('authorized.aboutUs.footer.disclaimers.title')}</div>
              <div className={styles.sectionContent}>
                <div className={styles.links}>
                  <div className={styles.link}>{t('authorized.aboutUs.footer.disclaimers.legalNotices')}</div>
                  <div className={styles.link}>{t('authorized.aboutUs.footer.disclaimers.privacyPolicy')}</div>
                  <div className={styles.link}>{t('authorized.aboutUs.footer.disclaimers.termsAndConditions')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
