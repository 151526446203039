import { useEffect, useState } from "react";

export enum ColorSchemas {
  Dark = "dark",
  Light = "light",
}

const mediaWatcher = window.matchMedia('(prefers-color-scheme: dark)');

export const useColorSchema = () => {
  const [colorSchema, setColorSchema] = useState<ColorSchemas>(mediaWatcher.matches ? ColorSchemas.Dark : ColorSchemas.Light);

  useEffect(() => {
    function handleChange(event: MediaQueryListEvent) {
      if (event.matches) {
        setColorSchema(ColorSchemas.Dark);
      } else {
        setColorSchema(ColorSchemas.Light);
      }
    }

    mediaWatcher.addEventListener("change", handleChange);

    return () => mediaWatcher.removeEventListener("change", handleChange);
  });

  return colorSchema;
};