import styles from './company-edit.module.css';

import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';

import AuthorizedContext from '@/authorized/authorized.context';
import Page from '@/authorized/components/page';
import { useGetCompanyEditDataQuery } from '@/authorized/pages/company-edit/company-edit.operations.generated';
import { useUpdateCompanyMutation, useUploadCompanyImageMutation } from './company-edit.utils';
import Divider from '@/shared/components/divider';
import Feed from '@/authorized/components/feed';
import Button, { ButtonVariants } from '@/shared/components/button';
import { renderEnumValue, shortNumber } from '@/shared/helpers';
import PostCard from '@/authorized/components/post';
import { Post, UpdateCompanyInput } from '@/shared/helpers/types';
import Modal, { Body, Footer, Head, ModalRef } from '@/authorized/components/modal';
import Form, { FormRef, FormValue } from '@/shared/components/form';
import TextInput from '@/shared/components/text-input';
import FileInput from '@/shared/components/file-input';
import Icon from '@/shared/components/icon';
import Select from '@/shared/components/select';
import moment from 'moment/moment';
import { endpoint } from '@/shared/helpers/fetcher';
import { history } from '@/authorized/components/router';
import { notify } from '@/authorized/components/notifications';
import { getFilePath } from '../../../shared/helpers/getFilePath';
import { useTranslation } from 'react-i18next';

const CompanyEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, isLoading } = useGetCompanyEditDataQuery({ id });

  const [subscribersCount] = useState(0);
  const [editData, setEditData] = useState<UpdateCompanyInput>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [coverUrl, setCoverUrl] = useState<string>();
  const { mutateAsync: updateCompany, isLoading: isUpdateCompanyLoading } = useUpdateCompanyMutation();
  const { mutateAsync: uploadCompanyImage, isLoading: isUploadCompanyImageLoading } = useUploadCompanyImageMutation();

  useEffect(() => {
    if (data?.company) {
      const newEditData = Object.fromEntries(
        Object.entries(data.company).filter(
          ([key]) =>
            !['_id', 'avatar', 'cover', 'subscribersCount', 'subscribedToCount', 'posts', 'subscribers'].includes(key)
        )
      );

      setEditData({
        ...newEditData,
      });
    }
  }, [data]);

  useEffect(() => {
    if (editData?.avatar) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (reader.result && typeof reader.result === 'string') {
          setAvatarUrl(reader.result);
        }
      });

      if (editData.avatar instanceof File) {
        reader.readAsDataURL(editData.avatar);
      }
    }

    if (editData?.cover) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (reader.result && typeof reader.result === 'string') {
          setCoverUrl(reader.result);
        }
      });

      if (editData.cover instanceof File) {
        reader.readAsDataURL(editData.cover);
      }
    }
  }, [editData]);

  const onEditSaveClick = async () => {
    if (data?.company && editData) {
      await updateCompany({
        id: data.company._id,
        input: Object.fromEntries(Object.entries(editData).filter(([key, value]) => value !== undefined)),
      });

      notify({
        prefix: <Icon name="done" />,
        message: 'Company successfully updated',
      });

      history.push(`/company/${data.company._id}`);
    }
  };

  const handleGalleryChange = async (file: File, target: keyof UpdateCompanyInput) => {
    const response = await uploadCompanyImage({ file });

    setEditData({
      ...editData,
      [target]: [response.uploadImage].concat(editData?.[target] ?? []),
    });
  };

  return (
    <Page title="CompanyEdit" loading={isLoading}>
      {!!data?.company && !!editData && (
        <div className={styles.company}>
          <div
            className={styles.cover}
            style={{ backgroundImage: `url(${coverUrl ?? getFilePath(data?.company?.cover?.filepath)})` }}
          >
            <div className={styles.overlay}>
              <Icon name="edit" size={32} />
            </div>
            <FileInput className={styles.input} onChange={(value) => setEditData({ ...editData, cover: value })} />
          </div>
          <div className={styles.header}>
            <div
              className={styles.avatar}
              style={{ backgroundImage: `url(${avatarUrl ?? getFilePath(data?.company?.avatar?.filepath)})` }}
            >
              <div className={styles.overlay}>
                <Icon name="edit" />
              </div>
              <FileInput className={styles.input} onChange={(value) => setEditData({ ...editData, avatar: value })} />
            </div>
            <input
              className={styles.name}
              defaultValue={editData.name ?? ''}
              onInput={(ev) => setEditData({ ...editData, name: ev.currentTarget.value })}
            />
            <div className={styles.divider}></div>
            <div className={styles.stats} style={{ opacity: 0.5 }}>
              <div className={styles.item}>
                <div className={styles.value}>{shortNumber(subscribersCount)}</div>
                <div className={styles.label}>{t('shared.followers')}</div>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.item}>
                <div className={styles.value}>{shortNumber(data.company.subscribedToCount)}</div>
                <div className={styles.label}>{t('shared.following')}</div>
              </div>
            </div>
            <div className={styles.actions}>
              <Button variant={ButtonVariants.Primary} onClick={onEditSaveClick} loading={isUpdateCompanyLoading}>
                {t('shared.save')}
              </Button>
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
          <div className={styles.badges}>
            <div className={styles.badge}>
              <div className={styles.label}>{t('shared.established')}</div>
              <input
                className={styles.value}
                defaultValue={editData.foundedAt ?? ''}
                onInput={(ev) => setEditData({ ...editData, foundedAt: ev.currentTarget.value })}
              />
            </div>
            <div className={styles.badge}>
              <div className={styles.label}>{t('shared.typeOfIndustry')}</div>
              <input
                className={styles.value}
                defaultValue={editData.type ?? ''}
                onInput={(ev) => setEditData({ ...editData, type: ev.currentTarget.value })}
              />
            </div>
            <div className={styles.badge}>
              <div className={styles.label}>{t('shared.annualSales')}</div>
              <input
                className={styles.value}
                defaultValue={editData.annualSales ?? ''}
                onInput={(ev) => setEditData({ ...editData, annualSales: ev.currentTarget.value })}
              />
            </div>
            <div className={styles.badge}>
              <div className={styles.label}>{t('shared.numberOfEmployees')}</div>
              <input
                className={styles.value}
                defaultValue={editData.employeesCount ?? ''}
                onInput={(ev) => setEditData({ ...editData, employeesCount: ev.currentTarget.value })}
              />
            </div>
            <div className={styles.badge}>
              <div className={styles.label}>{t('shared.website')}</div>
              <input
                className={styles.value}
                defaultValue={editData.website ?? ''}
                onInput={(ev) => setEditData({ ...editData, website: ev.currentTarget.value })}
              />
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
          <div className={styles.cards}>
            <div className={styles.card}>
              <div className={styles.icon}>
                <Icon name="cup" />
              </div>
              <div className={styles.title}>{t('authorized.company.mission')}</div>
              <textarea
                className={styles.text}
                defaultValue={editData.mission ?? ''}
                onInput={(ev) => setEditData({ ...editData, mission: ev.currentTarget.value })}
              ></textarea>
            </div>
            <div className={styles.card}>
              <div className={styles.icon}>
                <Icon name="profile-2user" />
              </div>
              <div className={styles.title}>{t('authorized.company.vision')}</div>
              <textarea
                className={styles.text}
                defaultValue={editData.vision ?? ''}
                onInput={(ev) => setEditData({ ...editData, vision: ev.currentTarget.value })}
              ></textarea>
            </div>
            <div className={styles.card}>
              <div className={styles.icon}>
                <Icon name="chart-2" />
              </div>
              <div className={styles.title}>{t('authorized.company.targetMarket')}</div>
              <textarea
                className={styles.text}
                defaultValue={editData.targetMarket ?? ''}
                onInput={(ev) => setEditData({ ...editData, targetMarket: ev.currentTarget.value })}
              ></textarea>
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
          <div className={styles.section}>
            <div className={styles.title}>{t('authorized.company.aboutUs')}</div>
            <textarea
              className={styles.text}
              defaultValue={editData.aboutUs ?? ''}
              onInput={(ev) => setEditData({ ...editData, aboutUs: ev.currentTarget.value })}
            ></textarea>
            <div className={styles.images}>
              {editData.aboutUsImages?.map(
                (image, index) =>
                  !!image && (
                    <div
                      className={styles.image}
                      key={index}
                      style={{ backgroundImage: `url(${getFilePath(image.filepath)})` }}
                    >
                      <div
                        className={styles.overlay}
                        onClick={() =>
                          setEditData({
                            ...editData,
                            aboutUsImages: (editData.aboutUsImages ?? []).filter((item) => item !== image),
                          })
                        }
                      >
                        <Icon name="trash" size={32} />
                      </div>
                    </div>
                  )
              )}
              <div className={styles.image}>
                <div className={styles.overlay} style={{ opacity: 1 }}>
                  <Icon name="add" size={32} />
                </div>
                <FileInput
                  className={styles.input}
                  onChange={(value) => handleGalleryChange(value as File, 'aboutUsImages')}
                />
              </div>
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
          <div className={styles.section}>
            <div className={styles.title}>{t('authorized.company.produceAndSell')}</div>
            <textarea
              className={styles.text}
              defaultValue={editData.produceAndSell ?? ''}
              onInput={(ev) => setEditData({ ...editData, produceAndSell: ev.currentTarget.value })}
            ></textarea>
            <div className={styles.images}>
              {editData.produceAndSellImages?.map(
                (image, index) =>
                  !!image && (
                    <div
                      className={styles.image}
                      key={index}
                      style={{ backgroundImage: `url(${getFilePath(image.filepath)})` }}
                    >
                      <div
                        className={styles.overlay}
                        onClick={() =>
                          setEditData({
                            ...editData,
                            produceAndSellImages: (editData.produceAndSellImages ?? []).filter(
                              (item) => item !== image
                            ),
                          })
                        }
                      >
                        <Icon name="trash" size={32} />
                      </div>
                    </div>
                  )
              )}
              <div className={styles.image}>
                <div className={styles.overlay}>
                  <Icon name="add" size={32} />
                </div>
                <FileInput
                  className={styles.input}
                  onChange={(value) => handleGalleryChange(value as File, 'produceAndSellImages')}
                />
              </div>
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
          <div className={styles.section}>
            <div className={styles.title}>{t('authorized.company.buy')}</div>
            <textarea
              className={styles.text}
              defaultValue={editData.buy ?? ''}
              onInput={(ev) => setEditData({ ...editData, buy: ev.currentTarget.value })}
            ></textarea>
            <div className={styles.images}>
              {editData.buyImages?.map(
                (image, index) =>
                  !!image && (
                    <div
                      className={styles.image}
                      key={index}
                      style={{ backgroundImage: `url(${getFilePath(image.filepath)})` }}
                    >
                      <div
                        className={styles.overlay}
                        onClick={() =>
                          setEditData({
                            ...editData,
                            buyImages: (editData.buyImages ?? []).filter((item) => item !== image),
                          })
                        }
                      >
                        <Icon name="trash" size={32} />
                      </div>
                    </div>
                  )
              )}
              <div className={styles.image}>
                <div className={styles.overlay}>
                  <Icon name="add" size={32} />
                </div>
                <FileInput
                  className={styles.input}
                  onChange={(value) => handleGalleryChange(value as File, 'buyImages')}
                />
              </div>
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
          <Feed
            style={{ opacity: 0.5 }}
            data={[...data.company.posts].sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1))}
            renderCard={(item) => <PostCard data={item as Partial<Post>} />}
          />
          {/*<div className={styles.header}>*/}
          {/*  <div className={styles.avatar} style={{ backgroundImage: `url(${"http://localhost:4000" + data?.company?.avatar?.filepath})` }}></div>*/}
          {/*  <div className={styles.name}>{data.company.name}</div>*/}
          {/*  <div className={styles.stats}>*/}
          {/*    <div className={styles.item}>*/}
          {/*      <div className={styles.value}>*/}
          {/*        {shortNumber(subscribersCount)}*/}
          {/*      </div>*/}
          {/*      <div className={styles.label}>Followers</div>*/}
          {/*    </div>*/}
          {/*    <div className={styles.divider}></div>*/}
          {/*    <div className={styles.item}>*/}
          {/*      <div className={styles.value}>*/}
          {/*        {shortNumber(data.company.subscribedToCount)}*/}
          {/*      </div>*/}
          {/*      <div className={styles.label}>Following</div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  {!!data.company.description && <div className={styles.description}>{data.company.description}</div>}*/}
          {/*  <div className={styles.actions}>*/}
          {/*    {isSessionCompany ? (*/}
          {/*        <Button onClick={() => {*/}
          {/*          editFormRef.current?.setValue(data?.company as FormValue);*/}
          {/*          editModalRef.current?.show();*/}
          {/*        }}>Edit Profile</Button>*/}
          {/*      ) : (*/}
          {/*        <>*/}
          {/*          <Button*/}
          {/*            variant={*/}
          {/*              isFollowed ? ButtonVariants.Default : ButtonVariants.Primary*/}
          {/*            }*/}
          {/*            onClick={onFollowClick}*/}
          {/*            loading={isSubscribeLoading || isUnsubscribeLoading}*/}
          {/*          >*/}
          {/*            {isFollowed ? "Unfollow" : "Follow"}*/}
          {/*          </Button>*/}
          {/*          <Button onClick={messageModalRef.current?.show}>Send Message</Button>*/}
          {/*        </>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<Divider style={{ margin: 0 }} />*/}
          {/*<Feed*/}
          {/*  data={[...data.company.posts].sort((a, b) => moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1)}*/}
          {/*  renderCard={(item) => <PostCard data={item as Partial<Post>} />}*/}
          {/*/>*/}
        </div>
      )}
    </Page>
  );
};

export default CompanyEdit;
