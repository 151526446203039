import styles from "./text-input.module.css";

import React, { useRef, useImperativeHandle } from "react";
import classNames from "classnames";

import { jsx } from "@/shared/helpers";

import { TextInputProps, TextInputRef } from "./text-input.types";
import { forwardRef } from "react";

const TextInput: React.ForwardRefRenderFunction<
  TextInputRef,
  React.PropsWithChildren<TextInputProps>
> = (props, ref) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  useImperativeHandle(ref, () => ({
    clearValue: () => {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    },
    setValue: (value) => {
      const { current: inputElement } = inputRef;

      if (inputElement) {
        inputElement.value = value;
      }
    },
  }));

  const onContainerClick = () => {
    const { current: inputElement } = inputRef;

    if (inputElement) {
      inputElement.focus();
    }
  };

  const onChange = (value: string) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.disabled]: props.disabled,
          [styles.multiline]: props.multiline,
        },
        props.className
      )}
      style={props.style}
      onClick={onContainerClick}
    >
      {jsx.if(props.label, <div className={styles.label}>{props.label}</div>)}
      <div className={styles.field}>
        {jsx.if(
          props.prefix,
          <>
            <div className={styles.prefix}>{props.prefix}</div>
            <div className={styles.divider}></div>
          </>
        )}
        {props.multiline ? (
          <textarea
            ref={inputRef as React.RefObject<HTMLTextAreaElement>}
            name={props.name}
            className={styles.input}
            placeholder={props.placeholder}
            disabled={props.disabled}
            readOnly={props.readOnly}
            rows={5}
            onChange={(e) => onChange(e.currentTarget.value)}
          />
        ) : (
          <input
            ref={inputRef as React.RefObject<HTMLInputElement>}
            name={props.name}
            className={styles.input}
            type={props.type}
            placeholder={props.placeholder}
            disabled={props.disabled}
            readOnly={props.readOnly}
            onChange={(e) => onChange(e.currentTarget.value)}
          />
        )}
        {jsx.if(
          props.suffix,
          <>
            <div className={styles.divider}></div>
            <div className={styles.suffix}>{props.suffix}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default forwardRef(TextInput);
