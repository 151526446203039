import styles from './post.module.css';
import { PostProps } from './post.types';
import Button, { ButtonVariants } from '@/shared/components/button';
import Icon from '@/shared/components/icon';
import { shortNumber } from '@/shared/helpers';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Post } from '@/shared/helpers/types';
import { Body, ModalRef } from '@/authorized/components/modal';
import Modal from '@/authorized/components/modal/modal.element';
import AuthorizedContext from '@/authorized/authorized.context';
import { useDislikeMutation, useLikeMutation } from '@/authorized/components/post/post.operations.generated';
import { Link } from 'react-router-dom';
import { getFilePath } from '../../../shared/helpers/getFilePath';
import { useTranslation } from 'react-i18next';

const PostCard = (props: PostProps) => {
  const { t } = useTranslation();
  const { session } = useContext(AuthorizedContext);
  const modalRef = useRef<ModalRef>(null);
  const [data, setData] = useState<Partial<Post>>();
  const [likesCount, setLikesCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  const { mutateAsync: like, isLoading: likeLoading } = useLikeMutation();
  const { mutateAsync: dislike, isLoading: dislikeLoading } = useDislikeMutation();

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setLikesCount(data?.likesCount ?? 0);
    setIsLiked(!!data?.likes?.some((like) => like.company._id === session._id));
  }, [data]);

  const onLikeClick = async () => {
    if (isLiked) {
      await dislike({ id: data?._id });
      setLikesCount(likesCount - 1);
      setIsLiked(false);
    } else {
      await like({ id: data?._id });
      setLikesCount(likesCount + 1);
      setIsLiked(true);
    }
  };

  const showPost = () => {
    modalRef.current?.show();
  };

  return (
    <>
      <Modal
        ref={modalRef}
        className={styles.modal}
        style={
          {
            '--modal-max-width': '1024px',
            alignItems: 'flex-start',
          } as React.CSSProperties
        }
      >
        <Body className={styles.body}>
          <div className={styles.header}>
            <Link to={`/company/${data?.company?._id}`}>
              <div className={styles.author}>
                <div
                  className={styles.avatar}
                  style={{ backgroundImage: `url(${getFilePath(data?.company?.avatar?.filepath)})` }}
                ></div>
                <div className={styles.company}>{data?.company?.name}</div>
              </div>
            </Link>
            <Button onClick={() => modalRef.current?.hide()}>
              <Icon name="close-circle" />
            </Button>
          </div>
          <div className={styles.coverContainer}>
            <img src={getFilePath(data?.cover?.filepath)} alt="cover" className={styles.cover} />
          </div>
          <div className={styles.actions}>
            <Button
              prefix={<Icon variant={'outline'} name="heart" />}
              variant={isLiked ? ButtonVariants.Primary : ButtonVariants.Default}
              onClick={onLikeClick}
              loading={likeLoading || dislikeLoading}
            >
              {shortNumber(likesCount)}
            </Button>
            {/*<Button prefix={<Icon name="messages" />}>*/}
            {/*  {shortNumber(selectedPost.comments)}*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  className={styles.bookmark}*/}
            {/*  variant={*/}
            {/*    selectedPost.saved*/}
            {/*      ? ButtonVariants.Primary*/}
            {/*      : ButtonVariants.Default*/}
            {/*  }*/}
            {/*>*/}
            {/*  <Icon name="bookmark" />*/}
            {/*</Button>*/}
          </div>
          <div className={styles.title}>{data?.title}</div>
          <div className={styles.content}>{data?.content}</div>
        </Body>
      </Modal>
      <div className={styles.card}>
        <div className={styles.header}>
          <Link to={`/company/${data?.company?._id}`}>
            <div className={styles.author}>
              <div
                className={styles.avatar}
                style={{ backgroundImage: `url(${getFilePath(data?.company?.avatar?.filepath)})` }}
              ></div>
              <div className={styles.company}>{data?.company?.name}</div>
            </div>
          </Link>
        </div>
        <div className={styles.coverContainer}>
          <img src={getFilePath(data?.cover?.filepath)} alt="cover" className={styles.cover} />
          <Button
            className={styles.readMore}
            suffix={<Icon name="arrow-right-1" />}
            variant={ButtonVariants.Primary}
            onClick={() => showPost()}
          >
            {t('shared.readMore')}
          </Button>
        </div>
        <div className={styles.actions}>
          <Button
            prefix={<Icon variant={'outline'} name="heart" />}
            variant={isLiked ? ButtonVariants.Primary : ButtonVariants.Default}
            onClick={onLikeClick}
            loading={likeLoading || dislikeLoading}
          >
            {shortNumber(likesCount)}
          </Button>
          {/*<Button prefix={<Icon name="messages" />}>*/}
          {/*  {shortNumber(item.comments)}*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  className={styles.bookmark}*/}
          {/*  variant={*/}
          {/*    item.saved ? ButtonVariants.Primary : ButtonVariants.Default*/}
          {/*  }*/}
          {/*>*/}
          {/*  <Icon name="bookmark" />*/}
          {/*</Button>*/}
        </div>
        <div className={styles.title}>{data?.title}</div>
        <div className={styles.content}>{data?.content}</div>
      </div>
    </>
  );
};

export default PostCard;
