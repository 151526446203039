import styles from "./divider.module.css";

import classNames from "classnames";

import { DividerProps } from "./divider.types";

export const Divider = (props: React.PropsWithChildren<DividerProps>) => {
  return (
    <div
      className={classNames(styles.divider, props.className)}
      style={props.style}
    >
      <div className={styles.line}></div>
      {!!props.children && (
        <div className={styles.children}>{props.children}</div>
      )}
      <div className={styles.line}></div>
    </div>
  );
};
