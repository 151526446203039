import styles from "./guest.module.css";
import {Route, Routes, useLocation} from "react-router-dom";
import Router from "@/authorized/components/router";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Suspense} from "react";
import Loader from "@/shared/components/loader";
import routes from "@/guest/routes";
import Notifications from "@/authorized/components/notifications";
import {GuestProps} from "@/guest/guest.types";
import GuestContext from "./guest.context";

const AnimatedRoutes = (props: GuestProps) => {
  const location = useLocation();

  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.pathname}
        classNames={{
          appear: styles.pageAppear,
          appearActive: styles.pageAppearActive,
          appearDone: styles.pageAppearDone,
          enter: styles.pageEnter,
          enterActive: styles.pageEnterActive,
          enterDone: styles.pageEnterDone,
          exit: styles.pageExit,
          exitActive: styles.pageExitActive,
          exitDone: styles.pageExitDone,
        }}
        timeout={300}
      >
        <Suspense fallback={<Loader active />}>
          <Routes location={location}>
            {routes.map((route) => (
              <Route key={route.path} {...route} {...props} />
            ))}
          </Routes>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
};

const Guest = (props: GuestProps) => {
  return (
    <Router>
      <GuestContext.Provider value={props}>
        <Notifications />
        <AnimatedRoutes {...props} />
      </GuestContext.Provider>
    </Router>
  );
};

export default Guest;