import { createContext } from "react";

import {Company} from "@/shared/helpers/types";

interface AuthorizedContextState {
  session: Company;
  onLogout: () => void;
  onReload: (cb: () => any) => any;
  setSubscribedToCount: (value: number) => void;
}

const AuthorizedContext = createContext<AuthorizedContextState>({} as AuthorizedContextState);

export default AuthorizedContext;