import styles from './authorized.module.css';

import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Loader from '@/shared/components/loader';
import Button, { ButtonVariants } from '@/shared/components/button';
import Icon from '@/shared/components/icon';
import { shortNumber } from '@/shared/helpers';
import Form, { FormRef, FormValue } from '@/shared/components/form';
import TextInput from '@/shared/components/text-input';

import Navigation, { NavigationItemProps } from '@/authorized/components/navigation';
import Router from '@/authorized/components/router';
import Notifications, { notify } from '@/authorized/components/notifications';
import Modal, { Head, Body, Footer, ModalRef } from '@/authorized/components/modal';

import routes from './routes';
import AuthorizedContext from './authorized.context';
import { AuthorizedProps } from '@/authorized/authorized.types';
import { useCreatePostMutation } from '@/authorized/authorized.utils';
import { CreatePostInput } from '@/shared/helpers/types';
import FileInput from '@/shared/components/file-input';
import { endpoint } from '@/shared/helpers/fetcher';
import { getFilePath } from '../shared/helpers/getFilePath';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.pathname}
        classNames={{
          appear: styles.pageAppear,
          appearActive: styles.pageAppearActive,
          appearDone: styles.pageAppearDone,
          enter: styles.pageEnter,
          enterActive: styles.pageEnterActive,
          enterDone: styles.pageEnterDone,
          exit: styles.pageExit,
          exitActive: styles.pageExitActive,
          exitDone: styles.pageExitDone,
        }}
        timeout={300}
      >
        <Suspense fallback={<Loader active />}>
          <Routes location={location}>
            {routes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Routes>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
};

const Authorized = (props: AuthorizedProps) => {
  const { t } = useTranslation();
  const createModalRef = useRef<ModalRef>(null);

  const reloadRef = useRef<() => void>();
  const formRef = useRef<FormRef>(null);
  const [createPostData, setCreatePostData] = useState<CreatePostInput>();
  const { mutateAsync: createPost, isLoading } = useCreatePostMutation();
  const [subscribedToCount, setSubscribedToCount] = useState(0);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = useCallback(() => {
    setSidebarVisible(!sidebarVisible);
  }, [sidebarVisible]);

  useEffect(() => {
    setSubscribedToCount(props.session.subscribedToCount ?? 0);
  }, [props.session]);

  const onCreatePost = async () => {
    if (!createPostData) {
      return;
    }

    await createPost({
      input: createPostData,
    });

    notify({
      prefix: <Icon name="done" />,
      message: 'Post successfully created',
    });

    createModalRef.current?.hide();

    if (reloadRef.current) {
      reloadRef.current();
    }
  };

  const navigationItems: NavigationItemProps[] = useMemo(
    () => [
      {
        label: t('authorized.navigation.home'),
        icon: 'home',
        path: '/',
        exact: true,
      },
      {
        icon: 'global-search',
        label: t('authorized.navigation.explore'),
        path: '/explore',
      },
      // {
      //   icon: "notification",
      //   label: "Notifications",
      //   path: "/notifications",
      // },
      {
        icon: 'send-2',
        label: t('authorized.navigation.messages'),
        path: '/messages',
      },
      {
        icon: 'money',
        label: t('authorized.navigation.pricing'),
        path: '/pricing',
      },
      {
        icon: 'people',
        label: t('authorized.navigation.aboutUs'),
        path: '/about',
      },
      // {
      //   icon: "setting",
      //   label: "Settings",
      //   path: "/settings",
      // },
    ],
    [t]
  );

  return (
    <Router>
      <Notifications />
      <Modal
        ref={createModalRef}
        style={
          {
            '--modal-max-width': '1024px',
            alignItems: 'flex-start',
          } as React.CSSProperties
        }
      >
        <Head>{t('authorized.createPost.title')}</Head>
        <Body>
          <Form
            ref={formRef}
            onChange={(value: FormValue) => setCreatePostData(value as CreatePostInput)}
            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
          >
            <TextInput
              name="title"
              label={t('authorized.createPost.form.title')}
              placeholder={t('authorized.createPost.form.titlePlaceholder')}
            ></TextInput>
            <FileInput
              name="cover"
              label={t('authorized.createPost.form.cover')}
              placeholder={t('authorized.createPost.form.coverPlaceholder')}
              suffix={<Icon name="import" />}
            ></FileInput>
            <TextInput
              style={{ gridColumn: 'auto / span 2' }}
              name="content"
              label={t('authorized.createPost.form.content')}
              multiline
            ></TextInput>
          </Form>
        </Body>
        <Footer>
          <Button
            onClick={() => {
              createModalRef.current?.hide();
              formRef.current?.clearValue();
            }}
          >
            {t('shared.cancel')}
          </Button>
          <Button
            onClick={() => {
              onCreatePost();
              formRef.current?.clearValue();
            }}
            variant={ButtonVariants.Primary}
            loading={isLoading}
          >
            {t('authorized.createPost.form.post')}
          </Button>
        </Footer>
      </Modal>
      <div className={styles.wrapper}>
        <div className={classNames(styles.sidebar, sidebarVisible && styles.visible)}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <svg width="24" height="24" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="80" height="256" rx="40" fill="#8C9EFF" fillOpacity="0.25" />
                <rect x="176" width="80" height="256" rx="40" fill="#8C9EFF" fillOpacity="0.25" />
                <path
                  d="M183.574 243.574L0 60V42.4264C0 18.9949 18.9949 0 42.4264 0C53.6786 0 64.4699 4.46993 72.4264 12.4264L256 196V213.574C256 237.005 237.005 256 213.574 256C202.321 256 191.53 251.53 183.574 243.574Z"
                  fill="#8C9EFF"
                />
                <rect x="176" y="56" width="80" height="200" rx="40" fill="#8C9EFF" />
                <rect width="80" height="200" rx="40" fill="#8C9EFF" />
              </svg>

              <div className={styles.text}>NEGOTIUM.</div>
            </div>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.profile}>
            <Link to={`/company/${props.session._id}`} onClick={() => setSidebarVisible(false)}>
              <div
                className={styles.avatar}
                style={{ backgroundImage: `url(${getFilePath(props.session?.avatar?.filepath)})` }}
              ></div>
            </Link>
            <Link to={`/company/${props.session._id}`} onClick={() => setSidebarVisible(false)}>
              <div className={styles.name}>{props.session.name}</div>
            </Link>
            <div className={styles.stats}>
              <div className={styles.item}>
                <div className={styles.value}>{shortNumber(props.session.subscribersCount)}</div>
                <div className={styles.label}>{t('shared.followers')}</div>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.item}>
                <div className={styles.value}>{shortNumber(subscribedToCount)}</div>
                <div className={styles.label}>{t('shared.following')}</div>
              </div>
            </div>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.createPost}>
            <Button
              className={styles.button}
              suffix={<Icon name="add" />}
              variant={ButtonVariants.Primary}
              onClick={() => {
                createModalRef.current?.show();
                setSidebarVisible(false);
              }}
            >
              {t('authorized.navigation.createPost')}
            </Button>
          </div>
          <div className={styles.nav}>
            <div className={styles.inner}>
              <div className={styles.content}>
                <Navigation items={navigationItems} onClick={() => setSidebarVisible(false)} />
              </div>
              <div className={styles.ad}>
                <img src="/img/app_banner.png" width="100%" alt="app" />
              </div>
            </div>
          </div>
          <div className={styles.logout}>
            <Button className={styles.button} suffix={<Icon name="logout-1" />} onClick={props.onLogout}>
              {t('authorized.navigation.logout')}
            </Button>
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.header}>
            <Link className={styles.logo} to="/">
              <div className={styles.text}>NEGOTIUM.</div>
            </Link>
            <Button onClick={toggleSidebar}>
              <Icon name={sidebarVisible ? 'close-circle' : 'menu-1'} />
            </Button>
          </div>
          <AuthorizedContext.Provider
            value={{
              session: {
                ...props.session,
                subscribedToCount,
              },
              onReload: (cb) => {
                reloadRef.current = cb;
              },
              setSubscribedToCount,
              onLogout: props.onLogout,
            }}
          >
            <AnimatedRoutes />
          </AuthorizedContext.Provider>
        </div>
      </div>
    </Router>
  );
};

export default Authorized;
