import styles from './navigation.module.css';

import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Icon from '@/shared/components/icon';
import { Link, useLocation } from 'react-router-dom';
import { NavigationProps } from './navigation.types';

const isPathActive = (path: string, asPath: string, exact?: boolean) => {
  if (exact) {
    return asPath === path;
  } else {
    return asPath.startsWith(path);
  }
};

interface ItemProps {
  label: string;
  icon?: string;
  path?: string;
  exact?: boolean;
  children?: ItemProps[];
  onClick?: () => void;
}

interface ItemButtonProps extends ItemProps {
  isExpanded?: boolean;
  tabIndex?: number;
}

const ItemButton = (props: ItemButtonProps) => {
  return (
    <button tabIndex={props.tabIndex} className={styles.button} onClick={props.onClick}>
      {props.icon && (
        <>
          <Icon className={styles.icon} name={props.icon} />
        </>
      )}
      <span className={styles.label}>{props.label}</span>
      {props.children && (
        <>
          <Icon className={styles.toggle} name={props.isExpanded ? 'arrow-up-2' : 'arrow-right-3'} />
        </>
      )}
    </button>
  );
};

const Item = (props: ItemProps) => {
  const location = useLocation();

  const childrenRef = useRef<HTMLDivElement>(null);
  const [childrenHeight, setChildrenHeight] = useState(0);

  let isActive = false;
  let containsActiveItem = false;

  if (props.path) {
    isActive = isPathActive(props.path, location.pathname, props.exact);
  }

  if (props.children) {
    containsActiveItem = props.children.some((child) => {
      if (child.path) {
        return isPathActive(child.path, location.pathname, child.exact);
      }

      return false;
    });
  }

  const [isExpanded, setIsExpanded] = useState(containsActiveItem);

  useEffect(() => {
    if (childrenRef.current) {
      setChildrenHeight(childrenRef.current.scrollHeight);
    }
  }, [childrenRef]);

  return (
    <div
      className={classNames(styles.item, {
        [styles.active]: isActive,
        [styles.containsActive]: containsActiveItem,
        [styles.expanded]: isExpanded,
      })}
    >
      {props.path ? (
        <Link className={styles.link} to={props.path}>
          <ItemButton {...props} tabIndex={-1} onClick={props.onClick} />
        </Link>
      ) : (
        <ItemButton {...props} tabIndex={0} onClick={() => setIsExpanded(!isExpanded)} isExpanded={isExpanded} />
      )}
      {props.children && (
        <div
          ref={childrenRef}
          className={styles.children}
          style={{ height: isExpanded ? childrenHeight || 'auto' : 0 }}
        >
          <div className={styles.line}></div>
          <div className={classNames(styles.list)}>
            {props.children.map((child) => (
              <Item key={child.label} {...child} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const Navigation = (props: NavigationProps) => {
  return (
    <div className={styles.navigation}>
      <div className={styles.list}>
        {props.items.map((item) => (
          <Item key={item.label} {...item} onClick={props.onClick} />
        ))}
      </div>
    </div>
  );
};

export default Navigation;
