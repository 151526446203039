import * as Types from '../shared/helpers/types/graphql.generated';

import { useMutation, UseMutationOptions } from 'react-query';
import {fetcher} from "@/shared/helpers/fetcher";

function _fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  const data = new FormData();

  data.append("operations", JSON.stringify({
    query,
    variables: {
      input: {
        ...(variables as any).input,
        cover: null,
      },
    },
  }));

  data.append('map', JSON.stringify({
    "0": ["variables.input.cover"],
  }));

  data.append("0", (variables as any).input.cover);

  return fetcher<TData, TVariables>(query, variables, data, "CreatePost");
}

export type CreatePostMutationVariables = Types.Exact<{
  input: Types.CreatePostInput;
}>;


export type CreatePostMutation = { createPost: { _id: any } };


export const CreatePostDocument = `
    mutation CreatePost($input: CreatePostInput!) {
  createPost(input: $input) {
    _id
  }
}
    `;
export const useCreatePostMutation = <
  TError = unknown,
  TContext = unknown
  >(options?: UseMutationOptions<CreatePostMutation, TError, CreatePostMutationVariables, TContext>) =>
  useMutation<CreatePostMutation, TError, CreatePostMutationVariables, TContext>(
    ['CreatePost'],
    (variables?: CreatePostMutationVariables) => _fetcher<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, variables)(),
    options
  );