import * as Types from '../../../shared/helpers/types/graphql.generated';

import { useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from '@/shared/helpers/fetcher';
export type LikeMutationVariables = Types.Exact<{
  id: Types.Scalars['ObjectId'];
}>;


export type LikeMutation = { like: { _id: any } };

export type DislikeMutationVariables = Types.Exact<{
  id: Types.Scalars['ObjectId'];
}>;


export type DislikeMutation = { dislike: { _id: any } };


export const LikeDocument = `
    mutation Like($id: ObjectId!) {
  like(id: $id) {
    _id
  }
}
    `;
export const useLikeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LikeMutation, TError, LikeMutationVariables, TContext>) =>
    useMutation<LikeMutation, TError, LikeMutationVariables, TContext>(
      ['Like'],
      (variables?: LikeMutationVariables) => fetcher<LikeMutation, LikeMutationVariables>(LikeDocument, variables)(),
      options
    );
export const DislikeDocument = `
    mutation Dislike($id: ObjectId!) {
  dislike(id: $id) {
    _id
  }
}
    `;
export const useDislikeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DislikeMutation, TError, DislikeMutationVariables, TContext>) =>
    useMutation<DislikeMutation, TError, DislikeMutationVariables, TContext>(
      ['Dislike'],
      (variables?: DislikeMutationVariables) => fetcher<DislikeMutation, DislikeMutationVariables>(DislikeDocument, variables)(),
      options
    );